<template>
	<div class="displayFlex-1 p30px wrapper">
		<!-- 标题 -->
		<MyTitleVue Title="Personal Details" />
		<!-- form表单 -->
		<el-form :model="personal_information" :rules="rules" ref="personalForm" label-position="left" class="mt20px my-form">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Full Name (as in NRIC)" prop="full_name" class="require">
						<el-input v-model="personal_information.full_name" @input="personal_information.full_name = $util.inputReg(personal_information.full_name)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Preferred Name" prop="surname" class="require">
						<el-input v-model="personal_information.surname" @input="personal_information.surname = $util.inputReg(personal_information.surname)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Identity Type" prop="identity_type" class="require">
						<el-select v-model="personal_information.identity_type" placeholder="">
							<el-option v-for="(item, index) in identityTypeOptions" :key="index" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="NRIC / Passport / FIN" prop="nric_passport_fin" class="require">
						<el-input v-model="personal_information.nric_passport_fin" @input="personal_information.nric_passport_fin = $util.inputReg(personal_information.nric_passport_fin)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Title" prop="title" class="require">
						<el-select v-model="personal_information.title" placeholder="" no-data-text="No Data" @change="changeTitle">
							<el-option v-for="(item, i) in characterType" :key="i" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Gender" prop="gender" class="require">
						<el-select v-model="personal_information.gender" placeholder="">
							<el-option label="Male" value="Male"></el-option>
							<el-option label="Female" value="Female"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Nationality" prop="nationality" class="require">
						<el-select v-model="personal_information.nationality" placeholder="" no-data-text="No Data">
							<el-option v-for="(item, i) in nationalityOptions" :key="i" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Race" prop="race">
						<el-select v-model="personal_information.race" placeholder="">
							<el-option v-for="item in raceOptions" :key="item.code" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="personal_information.race === 'Others'">
					<el-form-item label="Others Race Name" prop="race_content" class="require">
						<el-input v-model="personal_information.race_content" @input="personal_information.race_content = $util.inputReg(personal_information.race_content)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Proficient in spoken language" prop="language_spoken" class="require">
						<el-select v-model="personal_information.language_spoken" placeholder="">
							<el-option v-for="item in spokenLanguage" :key="item.code" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="personal_information.language_spoken === 'Others'">
					<el-form-item label="Others Proficient in spoken language" prop="language_spoken_content" class="require">
						<el-input v-model="personal_information.language_spoken_content" @input="personal_information.language_spoken_content = $util.inputReg(personal_information.language_spoken_content)" class="longinput"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Proficient in written language" prop="language_writen" class="require">
						<el-select v-model="personal_information.language_writen" placeholder="">
							<el-option v-for="item in writtenLanguage" :key="item.code" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="personal_information.language_writen === 'Others'">
					<el-form-item label="Others Proficient in written language" prop="language_writen_content" class="require">
						<el-input v-model="personal_information.language_writen_content" @input="personal_information.language_writen_content = $util.inputReg(personal_information.language_writen_content)" class="longinput"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Date of Birth" prop="date_of_birth" class="require">
						<el-date-picker v-model="personal_information.date_of_birth" type="date" format="dd/MM/yyyy" value-format="yyyy-MM-dd" placeholder="" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Marital Status" prop="marial_status" class="require">
						<el-select v-model="personal_information.marial_status" placeholder="">
							<el-option v-for="item in marialOptions" :label="item.name" :value="item.code" :key="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Email" prop="email" class="require">
						<el-input v-model="personal_information.email" @input="personal_information.email = $util.inputReg(personal_information.email)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- ---------------------------------------------------------------------------------------------- -->
		<!-- Contact No -->
		<MyTitleVue Title="Contact No" :titleIndex="2" />
		<el-form :model="personal_information" :rules="rules" ref="personalForm1" label-position="left" class="mt20px my-form">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Area" prop="contact_no_mobile_area" class="require">
						<el-select v-model="personal_information.contact_no_mobile_area" @change="validateMobile" placeholder="" no-data-text="No Data">
							<el-option v-for="(item, i) in areaOptions" :label="item.name" :value="item.code" :key="i" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Mobile" prop="contact_no_mobile" class="require">
						<el-input v-model="personal_information.contact_no_mobile" @input="personal_information.contact_no_mobile = $util.inputReg(personal_information.contact_no_mobile)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Area">
						<el-select v-model="personal_information.contact_no_home_area" placeholder="" no-data-text="No Data">
							<el-option v-for="(item, i) in areaOptions" :label="item.name" :value="item.code" :key="i" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Home">
						<el-input v-model="personal_information.contact_no_home" @input="personal_information.contact_no_home = $util.inputReg(personal_information.contact_no_home)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Area">
						<el-select v-model="personal_information.contact_no_office_area" placeholder="" no-data-text="No Data">
							<el-option v-for="(item, i) in areaOptions" :label="item.name" :value="item.code" :key="i" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Office">
						<el-input v-model="personal_information.contact_no_office" @input="personal_information.contact_no_office = $util.inputReg(personal_information.contact_no_office)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- ---------------------------------------------------------------------------------------------- -->
		<!-- residential Address -->
		<MyTitleVue Title="Residential Address" :titleIndex="2" />
		<el-form :model="personal_information" :rules="rules" ref="personalForm2" label-position="left" class="mt20px my-form">
			<el-row>
				<el-col :span="24">
					<el-form-item label="Block & Street Name" prop="residential_street" class="require" style="width: 95%;">
						<el-input v-model="personal_information.residential_street" @input="personal_information.residential_street = $util.inputReg(personal_information.residential_street)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Unit No." style="width: 95%;">
						<el-input v-model="personal_information.residential_unit_no" @input="personal_information.residential_unit_no = $util.inputReg(personal_information.residential_unit_no)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Building Name" style="width: 95%;">
						<el-input v-model="personal_information.residential_building_name" @input="personal_information.residential_building_name = $util.inputReg(personal_information.residential_building_name)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Country" prop="residential_country" class="require">
						<el-select v-model="personal_information.residential_country" @change="getAddressByCodeOrCountry(personal_information.residential_zip_code, 'residential', personal_information.residential_country)" placeholder="" no-data-text="No Data">
							<el-option v-for="(item, i) in countryCodes" :key="i" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Postal/Zip Code" prop="residential_zip_code" class="require">
						<el-input v-model="personal_information.residential_zip_code" @blur="getAddressByCodeOrCountry(personal_information.residential_zip_code, 'residential', personal_information.residential_country)" @input="personal_information.residential_zip_code = $util.inputReg(personal_information.residential_zip_code)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Mailing Address Same As Residential Address:" prop="name">
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="" prop="name">
						<SwitchBoxVue :status.sync="personal_information.address_same_as_residential" width="300px" />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- ---------------------------------------------------------------------------------------------- -->
		<!-- mailing Address -->
		<MyTitleVue Title="Mailing Address" :titleIndex="2" />
		<el-form :model="personal_information" :rules="rules" ref="personalForm3" label-position="left" class="mt20px my-form">
			<el-row>
				<el-col :span="24">
					<el-form-item label="Block & Street Name" prop="mailing_street" :class="personal_information.address_same_as_residential=== 'Y'  ? '' : 'require'" style="width: 95%;">
						<el-input v-model="personal_information.mailing_street" :disabled="personal_information.address_same_as_residential === 'Y'" @input="personal_information.mailing_street = $util.inputReg(personal_information.mailing_street)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Unit No." prop="name" style="width: 95%;">
						<el-input v-model="personal_information.mailing_unit_no" :disabled="personal_information.address_same_as_residential === 'Y'" @input="personal_information.mailing_unit_no = $util.inputReg(personal_information.mailing_unit_no)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Building Name" prop="name" style="width: 95%;">
						<el-input v-model="personal_information.mailing_building_name" :disabled="personal_information.address_same_as_residential === 'Y'" @input="personal_information.mailing_building_name = $util.inputReg(personal_information.mailing_building_name)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Country" prop="name" :class="personal_information.address_same_as_residential === 'Y' ? '' : 'require'">
						<el-select v-model="personal_information.mailing_country" :disabled="personal_information.address_same_as_residential === 'Y'" @change="getAddressByCodeOrCountry(personal_information.mailing_zip_code, 'mailing', personal_information.mailing_country)" placeholder="" no-data-text="No Data">
							<el-option v-for="(item, i) in countryCodes" :key="i" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Postal/Zip Code" prop="mailing_zip_code" :class="personal_information.address_same_as_residential === 'Y' ? '' : 'require'">
						<el-input v-model="personal_information.mailing_zip_code" :disabled="personal_information.address_same_as_residential === 'Y'" @blur="getAddressByCodeOrCountry(personal_information.mailing_zip_code, 'mailing', personal_information.mailing_country)" @input="personal_information.mailing_zip_code = $util.inputReg(personal_information.mailing_zip_code)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- ---------------------------------------------------------------------------------------------- -->
		<!-- Education -->
		<MyTitleVue Title="Education" :titleIndex="2" />
		<el-form :model="personal_information" :rules="rules" ref="personalForm4" label-position="left" class="mt20px my-form">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Educational Level" prop="education_level" class="require">
						<el-select v-model="personal_information.education_level" placeholder="">
							<el-option v-for="item in educationalOptions" :key="item.code" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- ---------------------------------------------------------------------------------------------- -->
		<!-- Employment -->
		<MyTitleVue Title="Employment" :titleIndex="2" />
		<el-form :model="personal_information" :rules="rules" ref="personalForm5" label-position="left" class="mt20px my-form">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Employment Status" prop="employment_status" class="require">
						<el-select v-model="personal_information.employment_status" placeholder="" @change="changeStatus">
							<el-option v-for="item in employmentOptions" :key="item.code" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="personal_information.employment_status === 'Others'">
					<el-form-item label="Other Employment Status" prop="employment_status_content" class="require">
						<el-input v-model="personal_information.employment_status_content" @input="personal_information.employment_status_content = $util.inputReg(personal_information.employment_status_content)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Company Name" prop="employer" style="width: 95%;" v-if="ifRequireByStatus" class="require">
						<el-input v-model="personal_information.employer" @input="personal_information.employer = $util.inputReg(personal_information.employer)"></el-input>
					</el-form-item>
					<el-form-item label="Company Name" style="width: 95%;" v-else>
						<el-input v-model="personal_information.employer" @input="personal_information.employer = $util.inputReg(personal_information.employer)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Occupation" prop="occupation" style="width: 95%;" class="require">
						<el-input v-model="personal_information.occupation" @input="personal_information.occupation = $util.inputReg(personal_information.occupation)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Block & Street Name" prop="employment_street" v-if="ifRequireByStatus" class="require" style="width: 95%;">
						<el-input v-model="personal_information.employment_street" @input="personal_information.employment_street = $util.inputReg(personal_information.employment_street)"></el-input>
					</el-form-item>
					<el-form-item label="Block & Street Name" v-else style="width: 95%;">
						<el-input v-model="personal_information.employment_street" @input="personal_information.employment_street = $util.inputReg(personal_information.employment_street)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Unit No." style="width: 95%;">
						<el-input v-model="personal_information.employment_unit_no" @input="personal_information.employment_unit_no = $util.inputReg(personal_information.employment_unit_no)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Building Name" style="width: 95%;">
						<el-input v-model="personal_information.employment_building_name" @input="personal_information.employment_building_name = $util.inputReg(personal_information.employment_building_name)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Country" prop="employment_country" v-if="ifRequireByStatus" class="require">
						<el-select v-model="personal_information.employment_country" placeholder="" @change="getAddressByCodeOrCountry(personal_information.employment_zip_code, 'employment', personal_information.employment_country)" no-data-text="No Data">
							<el-option v-for="(item, i) in countryCodes" :key="i" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
					<el-form-item label="Country" v-else>
						<el-select v-model="personal_information.employment_country" placeholder="" @change="getAddressByCodeOrCountry(personal_information.employment_zip_code, 'employment', personal_information.employment_country)" no-data-text="No Data">
							<el-option v-for="(item, i) in countryCodes" :key="i" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Postal/Zip Code" prop="employment_zip_code" v-if="ifRequireByStatus" class="require">
						<el-input v-model="personal_information.employment_zip_code" @blur="getAddressByCodeOrCountry(personal_information.employment_zip_code, 'employment', personal_information.employment_country)" @input="personal_information.employment_zip_code = $util.inputReg(personal_information.employment_zip_code)"></el-input>
					</el-form-item>
					<el-form-item label="Postal/Zip Code" v-else>
						<el-input v-model="personal_information.employment_zip_code" @blur="getAddressByCodeOrCountry(personal_information.employment_zip_code, 'employment', personal_information.employment_country)" @input="personal_information.employment_zip_code = $util.inputReg(personal_information.employment_zip_code)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- ---------------------------------------------------------------------------------------------- -->
		<!-- Health Condition -->
		<MyTitleVue Title="Health Condition" />
		<p class="mt10px mb10px">Do you smoke?</p>
		<SwitchBoxVue :status.sync="personal_information.smoker" width="300px" />
		<p class="mt10px mb10px">Do you or any of your family members have any medical condition that requires regular attention from clinic or hospital?</p>
		<SwitchBoxVue :status.sync="personal_information.heath_condition" width="300px" />
		<!-- heath_condition_description -->
		<el-input type="textarea" v-model="personal_information.heath_condition_description" v-show="personal_information.heath_condition === 'Y'" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Details of medical conditions" class='mt20px' size="large" @input="personal_information.heath_condition_description = $util.inputReg(personal_information.heath_condition_description)">
		</el-input>
	</div>
</template>

<script>
import MyTitleVue from "../components/MyTitle.vue"
import SwitchBoxVue from "../../../components/switchBox.vue"
import '@/common/style/MyForm.scss'
import { GET_areaCodeList } from '../../../utils/localStorage'
import { queryList, getAddress } from '@/api/KYC/index.js'
import _ from 'lodash';

export default {
	components: { MyTitleVue, SwitchBoxVue },
	data () {
		var mobileCheck = async (rules, value, callback) => {
			if (this.personal_information.contact_no_mobile_area === '+65') {
				if (value) {
					var reg = /^[8,9]\d{7}$/
					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error('The form of Mobile is not correct'))
					}
				} else {
					callback(new Error('Mobile is required'))
				}
			}
		}
		var validatorNRIC = (rule, value, callback) => {
			if (this.$validateIC(value) || this.personal_information.identity_type === 'Passport') {
				callback()
			} else {
				callback(new Error('The form of NRIC/Passport is not correct'))
			}
		}
		return {
			personal_information: {
				full_name: '',
				surname: '',
				nric_passport_fin: '',
				nationality: '',
				race: '',
				race_content: '',
				language_spoken: '',
				language_spoken_content: '',
				language_writen: '',
				language_writen_content: '',
				date_of_birth: '',
				marial_status: '',
				email: '',
				// contact No
				contact_no_mobile_area: '',
				contact_no_mobile: '',
				contact_no_home_area: '',
				contact_no_home: '',
				contact_no_office_area: '',
				contact_no_office: '',
				// Residential Address
				residential_street: '',
				residential_unit_no: '',
				residential_building_name: '',
				residential_country: '',
				residential_zip_code: '',
				address_same_as_residential: '',
				// mailing Address
				mailing_street: '',
				mailing_unit_no: '',
				mailing_building_name: '',
				mailing_country: '',
				mailing_zip_code: '',
				// Education
				education_level: '',
				// Employment
				employment_status: '',
				employer: '',
				occupation: '',
				employment_street: '',
				employment_status_content: '',
				employment_unit_no: '',
				employment_building_name: '',
				employment_country: '',
				employment_zip_code: '',
				// smoker
				smoker: 3,
				heath_condition: 3,
				title: '',
				gender: '',
				identity_type: '',

				heath_condition_description: '',
				additional_notice: '',
				client_source: '',
				mailing_id: '',

			},
			identityTypeOptions: null,
			raceOptions: null,
			spokenLanguage: null,
			writtenLanguage: null,
			educationalOptions: null,
			marialOptions: null,
			employmentOptions: null,
			nationalityOptions: null,
			countryCodes: null,
			areaOptions: null,
			address_same_as_residential: 3,
			ifRequireByStatus: false,
			rules: {
				full_name: [{ required: true, message: 'Full Name (as in NRIC) is required', trigger: 'blur' }],
				title: [{ required: true, message: 'Title is required', trigger: 'blur' }],
				gender: [{ required: true, message: 'Gender is required', trigger: 'blur' }],
				surname: [{ required: true, message: 'Preferred Name is required', trigger: 'blur' }],
				identity_type: [{required: true, message: 'Identity Type is required', trigger: 'blur'}],
				nric_passport_fin: [{ required: true, message: 'NRIC / Passport / FIN is required', trigger: 'blur' }, { validator: validatorNRIC, trigger: 'blur' }],
				nationality: [{ required: true, message: 'Nationality is required', trigger: 'blur' }],
				language_spoken: [{ required: true, message: 'Proficient in spoken language is required', trigger: 'blur' }],
				language_writen: [{ required: true, message: 'Proficient in written language is required', trigger: 'blur' }],
				date_of_birth: [{ required: true, message: 'Date of Birth is required', trigger: 'blur' }],
				marial_status: [{ required: true, message: 'Marital Status is required', trigger: 'blur' }],
				email: [{ required: true, message: 'Email is required', trigger: 'blur' }],
				contact_no_mobile_area: [{ required: true, message: 'Area is required', trigger: 'blur' }],
				contact_no_mobile: [
					{ required: true, message: 'Mobile is required', trigger: 'blur' },
					{ validator: mobileCheck, trigger: 'blur' }
				],
				residential_street: [{ required: true, message: 'Block & Street Name is required', trigger: 'blur' }],
				residential_country: [{ required: true, message: 'Country is required', trigger: 'blur' }],
				residential_zip_code: [{ required: true, message: 'Postal/Zip Code is required', trigger: 'blur' }],
				mailing_street: [{ required: true, message: 'Block & Street Name is required', trigger: 'blur' }],
				mailing_country: [{ required: true, message: 'Country is required', trigger: 'blur' }],
				mailing_zip_code: [{ required: true, message: 'Postal/Zip Code is required', trigger: 'blur' }],
				education_level: [{ required: true, message: 'Educational Level is required', trigger: 'blur' }],
				employment_status_content: [{ required: true, message: 'Other Employment Status is required', trigger: 'blur' }],
				employment_status: [{ required: true, message: 'Employment Status is required', trigger: 'blur' }],
				employer: [{ required: true, message: 'Company Name is required', trigger: 'blur' }],
				employment_street: [{ required: true, message: 'Block & Street Name is required', trigger: 'blur' }],
				employment_country: [{ required: true, message: 'Country is required', trigger: 'blur' }],
				employment_zip_code: [{ required: true, message: 'Postal/Zip Code Name is required', trigger: 'blur' }],
				occupation: [{ required: true, message: 'Occupation is required', trigger: 'blur' }],
			},
			// kyc所有数据
			kycAllData: {},
			characterType: [
				{name: 'Mr', code: 'Mr'},
				{name: 'Mrs', code: 'Mrs'},
				{name: 'Mdm', code: 'Mdm'},
				{name: 'Miss', code: 'Miss'},
				{name: 'Dr', code: 'Dr'},
			],
			timer:null
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		'personal_information.address_same_as_residential': {
			handler (newV, oldV) {
				// val=Y 邮寄地址与住宅地址相同
				// val=N 邮寄地址与住宅地址不同
				console.log(newV, oldV);
				if (newV === 'Y') {
					this.personal_information.mailing_street = this.personal_information.residential_street
					this.personal_information.mailing_unit_no = this.personal_information.residential_unit_no
					this.personal_information.mailing_building_name = this.personal_information.residential_building_name
					this.personal_information.mailing_country = this.personal_information.residential_country
					this.personal_information.mailing_zip_code = this.personal_information.residential_zip_code
				} else if (newV === 'N' && oldV === 'Y') {
					this.personal_information.mailing_street = ''
					this.personal_information.mailing_unit_no = ''
					this.personal_information.mailing_building_name = ''
					this.personal_information.mailing_country = ''
					this.personal_information.mailing_zip_code = ''
				}
			}
		},
		'personal_information.residential_street' (val) {
			if (this.personal_information.address_same_as_residential === 'Y') this.personal_information.mailing_street = val
		},
		'personal_information.residential_unit_no' (val) {
			if (this.personal_information.address_same_as_residential === 'Y') this.personal_information.mailing_unit_no = val
		},
		'personal_information.residential_building_name' (val) {
			if (this.personal_information.address_same_as_residential === 'Y') this.personal_information.mailing_building_name = val
		},
		'personal_information.residential_country' (val) {
			if (this.personal_information.address_same_as_residential === 'Y') this.personal_information.mailing_country = val
		},
		'personal_information.residential_zip_code' (val) {
			if (this.personal_information.address_same_as_residential === 'Y') this.personal_information.mailing_zip_code = val
		},
		'personal_information.date_of_birth' (val) {
			if (val && this.kycAllData.kyc.client[this.kycClient].needs_analysis.education_fund.length > 0) {
				if (this.kycAllData.kyc.client[this.kycClient].needs_analysis.education_fund[0].dependant_id == 'myself') this.kycAllData.kyc.client[this.kycClient].needs_analysis.education_fund[0].current_age = this.$getAgebyDate(val)
			}
		},
		'personal_information.language_spoken' (val) {
			if (val !== 'Others') this.personal_information.language_spoken_content = ''
		},
		'personal_information.language_writen' (val) {
			if (val !== 'Others') this.personal_information.language_writen_content = ''
		},
		'personal_information.surname' (val) {
			if (val) {
				if (this.timer) {
          clearTimeout(this.timer)
        }
				this.timer = setTimeout(() => {
          this.setKYC(this.kycClient)
					this.$parent.setClientName()
        }, 2000)
			}
		},
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal, 'kycClient watch')
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		changeTitle (val) {
			if (val === 'Dr') {
				this.personal_information.gender = ''
			} else if (val == 'Mr') {
				this.personal_information.gender = 'Male'
			} else {
				this.personal_information.gender = 'Female'
			}
		},
		// 设置下拉选得值
		setSelectOptions () {
			this.identityTypeOptions = global.identityType
			this.educationalOptions = global.educational
			this.raceOptions = global.race
			this.spokenLanguage = global.spokenLanguage
			this.writtenLanguage = global.writtenLanguage
			this.marialOptions = global.marial
			this.employmentOptions = global.employmentStatus
			this.nationalityOptions = []
			this.countryCodes = []
			this.areaOptions = []
			GET_areaCodeList().map(list => {
				let area = list.areaName + '(' + list.areaCode + ')'
				this.areaOptions.push({ code: list.areaCode, name: area })
			})
			queryList({}).then(res => {
				res.content.countryList.rows.map(item => {
					this.countryCodes.push({
						code: item.id,
						name: item.englishShortName
					})
				})
				res.content.nationalityList.rows.map(item => {
					this.nationalityOptions.push({
						code: item.id,
						name: item.nationality
					})
				})
			})
		},
		validateMobile () {
			this.$refs.personalForm1.validateField('contact_no_mobile', valid => { })
		},
		// 判断form表单是否通过校验
		submitForm () {
			const p = new Promise((resolve, reject) => {
				this.$refs.personalForm.validate((valid) => {
					if (valid) {
						resolve()
					}
				})
			})
			const p1 = new Promise((resolve, reject) => {
				this.$refs.personalForm1.validate((valid) => {
					if (valid) {
						resolve()
					}
				})
			})
			const p2 = new Promise((resolve, reject) => {
				this.$refs.personalForm2.validate((valid) => {
					if (valid) {
						resolve()
					}
				})
			})
			const p3 = new Promise((resolve, reject) => {
				this.$refs.personalForm3.validate((valid) => {
					if (valid) {
						resolve()
					}
				})
			})
			const p4 = new Promise((resolve, reject) => {
				this.$refs.personalForm4.validate((valid) => {
					if (valid) {
						resolve()
					}
				})
			})
			const p5 = new Promise((resolve, reject) => {
				this.$refs.personalForm5.validate((valid) => {
					if (valid) {
						resolve()
					}
				})
			})
			Promise.all([p, p1, p2, p3, p4, p5]).then((result) => {
				this.setKYC(this.kycClient)
				this.$emit('nextBtnRequest')
				return true
			})
		},
		//修改职业状态
		changeStatus (val) {
			this.ifRequireByStatus = val !== 'Retired' && val !== 'Not Employed'
		},
		// 从kycAllData获取当前页面数据
		getDataFromAll (val) {
			Object.keys(this.personal_information).map(key => {
				this.personal_information[key] = this.kycAllData.kyc.client[val].personal_information[key]
			})
			// 部分用户信息赋值（before start页面中传入） 
			let client0 = this.kycAllData.kyc.client[val].personal_information
			this.personal_information.full_name = client0.full_name
			this.personal_information.surname = client0.surname
			this.personal_information.identity_type = client0.identity_type
			this.personal_information.nric_passport_fin = client0.nric_passport_fin
			this.personal_information.contact_no_mobile = client0.contact_no_mobile
			this.personal_information.contact_no_mobile_area = client0.contact_no_mobile_area
			this.ifRequireByStatus = this.personal_information.employment_status !== 'Retired' && this.personal_information.employment_status !== 'Not employed'
		},
		setKYC (val) {
			this.personal_information.additional_notice = this.kycAllData.kyc.client[val].personal_information.additional_notice
			// Object.keys(this.kycAllData.kyc.client[val].personal_information).map(key => {
			// 	this.kycAllData.kyc.client[val].personal_information[key] = this.personal_information[key]
			// })
			// mailing_street等不存在时无法赋值，所以改为下面这种赋值方式
			this.kycAllData.kyc.client[val].personal_information = this.personal_information
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// country/zip填写完调用接口
		async getAddress (zip, type) {
			let res = await getAddress({ zip });
			res = res.content
			let address = {};
			if (type == 'mailing') {
				address = {
					mailing_street: res.street,
					mailing_building_name: res.unit_no
				};
			} else if (type == 'residential') {
				address = {
					residential_street: res.street,
					residential_building_name: res.unit_no
				};
			} else if (type == 'employment') {
				address = {
					employment_street: res.street,
					employment_building_name: res.unit_no
				}
			}
			Object.keys(address).map(key => {
				this.personal_information[key] = address[key] === '' ? this.personal_information[key] : address[key]
			})
		},
		getAddressByCodeOrCountry (code, type, country) {
			if (code && (country === '201' || country === '249')) this.getAddress(code, type)
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.setSelectOptions()
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
	destroyed () { // this.setKYC(this.kycClient)
	}
}
</script>
<style lang='css' scoped>
.el-form-item {
	width: 90%;
}
</style>