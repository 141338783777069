<template>
	<div class='wrapper'>
		<p>Would you like your existing insurance and investment portfolio to
			be taken into consideration for the needs analysis and recommendation(s)?</p>
		<SwitchBoxVue :status.sync="existing_investments_insurance.is_consideration_exist_portfolio" width="200px" />
		<!-- 选择yes时展示内容 -->
		<div v-show="existing_investments_insurance.is_consideration_exist_portfolio === 'Y'" class="yesBox displayFlex flexColumn">
			<!-- ----------------------------------------------------------------------------------------- -->
			<el-table :data="CashTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Investments">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						{{scope.row.value}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 选择no时展示内容 -->
		<div v-show="existing_investments_insurance.is_consideration_exist_portfolio === 'N'" class="yesBox">
			<el-input type="textarea" v-model="existing_investments_insurance.no_consideration_reson" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" size="large" @input="existing_investments_insurance.no_consideration_reson = $util.inputReg(existing_investments_insurance.no_consideration_reson)">
			</el-input>
		</div>
	</div>
</template>

<script>

import SwitchBoxVue from "../../../components/switchBox.vue";
import MyTitle from "../components/MyTitle.vue";
export default {
	components: { SwitchBoxVue, MyTitle },
	data () {
		return {
			existing_investments_insurance: {
				additional_notice: '',
				no_consideration_reson: '',
				existing_investments: [],
				existing_policies: [],
				is_consideration_exist_portfolio: '',
			},
			kycAllData: {}
		};
	},
	computed: {
		CashTableData () {
			return [{
				title: 'Investments',
				value: this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].existing_investments_insurance.existing_investments.length,
				path: '/ExistingInvestmentsList'
			}, {
				title: 'Life/Health Polices',
				value: this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].existing_investments_insurance.existing_policies.length,
				path: '/ExistingPoliciesList'
			}]
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		},
		'existing_investments_insurance.is_consideration_exist_portfolio' () {
			this.setKYC(this.kycClient)
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			this.$emit('nextBtnRequest')
		},
		setKYC (val) {
			Object.keys(this.kycAllData.kyc.client[val].existing_investments_insurance).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].existing_investments_insurance[key] = this.existing_investments_insurance[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		getDataFromAll (val) {
			Object.keys(this.existing_investments_insurance).map(key => {
				this.existing_investments_insurance[key] = this.kycAllData.kyc.client[val].existing_investments_insurance[key]
			})
			// 没有值否则默认展示Y
			if (!this.existing_investments_insurance.is_consideration_exist_portfolio) this.existing_investments_insurance.is_consideration_exist_portfolio = 'Y'
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
	destroyed () { // this.setKYC(this.kycClient)
	}
}
</script>
<style lang='css' scoped>
.yesBox {
	padding: 30px 0;
}
span {
	line-height: 50px;
}
p {
	color: #6c6c6c;
	font-size: 22px;
	margin-bottom: 20px;
}
</style>