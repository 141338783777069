<template>
	<div class='wrapper'>
		<p>Getting your cash flow helps to determine the affordability of the
			recommendation(s) for your financial needs. Would you like to
			provide information on your cash flow?</p>
		<SwitchBoxVue :status.sync="cashflow.is_provide_cash_flow" width="200px" />
		<!-- 选择yes时展示内容 -->
		<div v-show="cashflow.is_provide_cash_flow === 'Y'" class="yesBox displayFlex flexColumn">
			<!-- Cash-------------------------------------------------------------------------------------- -->
			<MyTitle Title="Cash" class="mt30px" />
			<el-table :data="CashTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Monthly Cash Inflow">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S${{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="displayFlex mt20px">
				<span class="w70 TextAlginEnd" style="color: #606266;">Shortfall</span>
				<span class="w30 ml20px" :style="{color: parseFloat(cashflow.monthly_cash_shortfall_surplus) < 0 ? 'red' : 'green'}">S$ {{$formatMoney(cashflow.monthly_cash_shortfall_surplus)}}</span>
			</div>
			<!-- CPF-------------------------------------------------------------------------------------- -->
			<MyTitle Title="CPF" class="mt30px" />
			<el-table :data="CPFTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Monthly CPF Inflow">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S${{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="displayFlex mt20px mb20px">
				<span class="w70 TextAlginEnd" style="color: #606266;">Shortfall</span>
				<span class="w30 ml20px" :style="{color: parseFloat(cashflow.cpf_shortfall_surplus) < 0 ? 'red' : 'green'}">S$ {{$formatMoney(cashflow.cpf_shortfall_surplus)}}</span>
			</div>
			<!-- issue -->
			<p>Do you foresee any factors within the next 12-18 months which may significantly increase or decrease your current income and expenditure position?</p>
			<SwitchBoxVue :status.sync="cashflow.is_income_expenditure_change_dramatically" width="200px" class="mb10px"/>
			<el-input type="textarea" v-if="cashflow.is_income_expenditure_change_dramatically === 'Y'" v-model="cashflow.income_expenditure_change_details" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" size="large" @input="cashflow.income_expenditure_change_details = $util.inputReg(cashflow.income_expenditure_change_details)">
			</el-input>
		</div>
		<!-- 选择no时展示内容 -->
		<div v-show="cashflow.is_provide_cash_flow === 'N'" class="yesBox">
			<el-input type="textarea" v-model="cashflow.no_provide_reason" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" size="large" @input="cashflow.no_provide_reason = $util.inputReg(cashflow.no_provide_reason)">
			</el-input>
		</div>
	</div>
</template>

<script>

import SwitchBoxVue from "../../../components/switchBox.vue";
import MyTitle from "../components/MyTitle.vue";
export default {
	components: { SwitchBoxVue, MyTitle },
	data () {
		return {
			cashflow: {
				additional_notice: '',
				is_provide_cash_flow: 'Y', //是否愿意填写现金流信息
				no_provide_reason: '', // 不填写现金流信息的原因
				// Cash
				monthly_cash_shortfall_surplus: '',
				// 二级 Monthly Cash Inflow
				total_monthly_income: '',
				gross_monthly_income: '',
				less_cpf_contribution: '',
				add_other_income: '',
				// 二级 Monthly Cash Outflow
				monthly_cash_outflow_total: '',
				utilities: '',
				groceries: '',
				property_tax: '',
				maid_expenses: '',
				cash_mortgage: '',
				household_others: '',
				household_total: '',

				allowance_parents: '',
				allowance_children: '',
				school_fees: '',
				medical_mxpenses: '',
				dependants_others: '',
				dependants_total: '',
				// CPF
				cpf_shortfall_surplus: '',
				// 二级 Monthly CPF Inflow
				cpf_total: '',
				cpf_oa_inflow: '',
				cpf_sa_inflow: '',
				cpf_medisave_inflow: '',
				// 二级 Monthly CPF Ouflow
				cpf_outflow_total: '',
				mortgage_cpf_oa: '',
				investment_cpf_oa: '',
				investment_cpf_sa: '',
				cpf_ma_outflow: '',
				// 显著+or-收入、支出
				is_income_expenditure_change_dramatically: '',
				income_expenditure_change_details: '',
				// 不确定字段
				is_reserve_half_assets: '',
			},
			kycAllData: {}
		};
	},
	computed: {
		CashTableData () {
			return [{
				title: 'Monthly Cash Inflow',
				value: this.cashflow.total_monthly_income,
				path: '/MonthlyCashInflow'
			}, {
				title: 'Monthly Cash Outflow',
				value: this.cashflow.monthly_cash_outflow_total,
				path: '/MonthlyCashOutflow'
			}]
		},
		CPFTableData () {
			return [{
				title: 'Monthly CPF Inflow',
				value: this.cashflow.cpf_total,
				path: '/MonthlyCPFInflow',
			}, {
				title: 'Monthly CPF Ouflow',
				value: this.cashflow.cpf_outflow_total,
				path: '/MonthlyCPFOutflow',
			}]
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			// 如果is_provide_cash_flow==='Y'&&monthly_cash_shortfall_surplus<=0 则弹出提示
			if (this.cashflow.is_provide_cash_flow === 'Y' && parseFloat(this.cashflow.monthly_cash_shortfall_surplus) <= 0) {
				this.$confirm('Do you wish to re-assess your cash flow?', 'Alert', {
					confirmButtonText: 'NO',
					cancelButtonText: 'YES',
				}).then(() => {
					this.$emit('nextBtnRequest')
				})
			} else {
				this.$emit('nextBtnRequest')
			}
		},
		setKYC (val) {
			Object.keys(this.kycAllData.kyc.client[val].cashflow).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].cashflow[key] = this.cashflow[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		getDataFromAll (val) {
			Object.keys(this.cashflow).map(key => {
				this.cashflow[key] = this.kycAllData.kyc.client[val].cashflow[key]
			})
			this.cashflow.monthly_cash_outflow_total = parseFloat(this.cashflow.household_total) + parseFloat(this.cashflow.dependants_total)
			// Cash total=inflow-outflow
			this.cashflow.monthly_cash_shortfall_surplus = parseFloat(this.cashflow.total_monthly_income) - parseFloat(this.cashflow.monthly_cash_outflow_total)
			// CPF total=inflow-outflow
			this.cashflow.cpf_shortfall_surplus = parseFloat(this.cashflow.cpf_total) - parseFloat(this.cashflow.cpf_outflow_total)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
	destroyed () { // this.setKYC(this.kycClient)
	}
}
</script>
<style lang='css' scoped>
p {
	color: #6c6c6c;
	font-size: 22px;
	margin-bottom: 20px;
}
.yesBox {
	padding: 30px 0;
}
span {
	line-height: 24px;
}
</style>