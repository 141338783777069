<template>
	<div class='wrapper'>
		<p>It is recommended for you to be accompanied by a Trusted Individual
			if you belong to any two of the following profiles.</p>
		<Checkbox checkBoxType="list" :checkedData="checkedData" :content="checkBoxContent" :disabled="true">
		</Checkbox>
		<!-- 当选择项大于等于两项时展示 -->
		<div v-show="showMore">
			<p class="mb20px">Would you like to be accompanied by a Trusted Individual?</p>
			<SwitchBoxVue width="200px" :status.sync="trusted_individual.is_accompanied" />
			<!-- 选择yes时 -->
			<div v-if="trusted_individual.is_accompanied === 'Y'">
				<MyTitle Title="Trusted Individual Condition" class="mt20px" />
				<el-form :model="trusted_individual" :rules="rules" ref="TrustedForm" label-position="left" class="mt20px my-form">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Name" class="require" prop="trusted_individual" :rules="[{ required: true, message: 'Name is required', trigger: 'blur' }]">
								<el-input v-model="trusted_individual.trusted_individual"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="NRIC Number" class="require" prop="trusted_individual_nricnumber">
								<el-input v-model="trusted_individual.trusted_individual_nricnumber"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Area" class="require" prop="trusted_individual_area" :rules="[{ required: true, message: 'Area is required', trigger: 'blur' }]">
								<el-select v-model="trusted_individual.trusted_individual_area" no-data-text="No Data" placeholder="">
									<el-option v-for="(item, index) in areaOptions" :key="index" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Contact No" class="require" prop="trusted_individual_content_number" :rules="[{ required: true, message: 'Contact No is required', trigger: 'blur' }]">
								<el-input v-model="trusted_individual.trusted_individual_content_number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Relationship to client" class="require" prop="trusted_individual_relationship" :rules="[{ required: true, message: 'Relationship to client is required', trigger: 'blur' }]">
								<el-select v-model="trusted_individual.trusted_individual_relationship" placeholder="">
									<el-option v-for="item in relationship" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<!-- 选择no时 -->
			<el-input type="textarea" v-model="trusted_individual.is_not_accompanied_reason" v-if="trusted_individual.is_accompanied === 'N'" 
			  :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" class='mt20px' size="large"
				@input="trusted_individual.is_not_accompanied_reason = $util.inputReg(trusted_individual.is_not_accompanied_reason)">
			</el-input>
		</div>
	</div>
</template>

<script>
import Checkbox from "../components/checkBox.vue";
import SwitchBoxVue from "../../../components/switchBox.vue";
import MyTitle from "../components/MyTitle.vue";
import { GET_areaCodeList } from '../../../utils/localStorage';
export default {
	components: { Checkbox, SwitchBoxVue, MyTitle },
	data () {
		var validatorNRIC = (rule, value, callback) => {
			if (this.$validateIC(value)) {
				callback()
			} else {
				callback(new Error('The form of NRIC/Passport is not correct'))
			}
		}
		return {
			rules: {
				trusted_individual_nricnumber: [
					{ required: true, message: 'NRIC Number is required', trigger: 'blur' },
					{ validator: validatorNRIC, trigger: 'blur' }
				],
			},
			checkedData: [],
			checkBoxContent: ['62 years of age or older', 'Below GCE ’N’ or ‘O’ level certification or equivalent academicqualification',
				'Not proficient in spoken or written English'],
			showMore: false,
			trusted_individual: {
				additional_notice: '',
				is_old: 'N',
				is_gce_no: 'N',
				is_not_proficient_english: 'N',
				is_accompanied: '', //是否由一个值得信赖的人陪同
				trusted_individual: '',
				trusted_individual_nricnumber: '',
				trusted_individual_area: '',
				trusted_individual_content_number: '',
				trusted_individual_relationship: '',
				is_not_accompanied_reason: '',
				// 未明确字段
				trusted_individual_langunge: '',
				field: '',
			},
			areaOptions: [],
			relationship: [],
			// kyc所有数据
			kycAllData: {}
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		submitForm () {
			if (!this.trusted_individual.is_accompanied || this.checkedData.length <= 1) {
				this.setKYC(this.kycClient)
				return this.$emit('nextBtnRequest')
			}
			if (this.trusted_individual.is_accompanied === 'Y') {
				this.$refs.TrustedForm.validate((valid) => {
					if (valid) {
						this.setKYC(this.kycClient)
						this.$emit('nextBtnRequest')
					}
				})
			}
			if (this.trusted_individual.is_accompanied === 'N') {
				// 选择no时，原因必填
				if (!this.trusted_individual.is_not_accompanied_reason) {
					return this.$message.warning('Please state reason!')
				} else {
					this.setKYC(this.kycClient)
					this.$emit('nextBtnRequest')
				}
			}
		},
		setKYC (val) {
			Object.keys(this.kycAllData.kyc.client[val].trusted_individual).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].trusted_individual[key] = this.trusted_individual[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		checkedByPersonData (val) {
			this.checkedData = []
			let person = this.kycAllData.kyc.client[val].personal_information
			console.log(person, 'person');
			if (this.$getAgebyDate(person.date_of_birth) >= 62) {
				this.checkedData.push('62 years of age or older')
				this.trusted_individual.is_old = 'Y'
			} else {
				this.trusted_individual.is_old = 'N'
			}
			if (person.education_level.includes('Primary') || person.education_level.includes('Secondary')) {
				this.checkedData.push('Below GCE ’N’ or ‘O’ level certification or equivalent academicqualification')
				this.trusted_individual.is_gce_no = 'Y'
			} else {
				this.trusted_individual.is_gce_no = 'N'
			}
			if (!person.language_spoken.includes('English') || !person.language_writen.includes('English')) {
				this.checkedData.push('Not proficient in spoken or written English')
				this.trusted_individual.is_not_proficient_english = 'Y'
			} else {
				this.trusted_individual.is_not_proficient_english = 'N'
			}
			// 选中大于等于2
			this.showMore = this.checkedData.length >= 2
			if (this.showMore) this.$message.error('The customer is a selected client!')
			console.log(this.checkedData, 'checkedData');
		},
		getDataFromAll (val) {
			Object.keys(this.trusted_individual).map(key => {
				this.trusted_individual[key] = this.kycAllData.kyc.client[val].trusted_individual[key]
			})
			// 根据1.1数据判断1.2是否选中
			this.checkedByPersonData(this.kycClient)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.areaOptions = []
		GET_areaCodeList().map(list => {
			let area = list.areaName + '(' + list.areaCode + ')'
			this.areaOptions.push({ code: list.areaCode, name: area })
		})
		this.relationship = global.relationship
		// 获取kyc所有数据
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
	destroyed () { // this.setKYC(this.kycClient)
	}
}
</script>
<style lang='css' scoped>
p {
	color: #6c6c6c;
	font-size: 22px;
}
</style>