<template>
	<div class='wrapper'>
		<!-- 未评估 -->
		<template v-if="$store.state.KYC.CKA === -1">
			<!-- Notice to Client -->
			<MyTitle Title="Notice to Client" />
			<p>{{paragraph1}}</p>
			<p>{{paragraph2}}</p>
			<!-- Educational or professional qualification -->
			<MyTitle Title="Educational or professional qualification" class="mt30px" />
			<!-- 问题1 -->
			<p>{{paragraph3}}</p>
			<p>{{paragraph4}}</p>
			<SwitchBoxVue :status.sync="customer_knowledge_assessment.hold_finance_diploma" :placeholder="placeholder" width="200px" class="mb20px"/>
			<el-input type="textarea" v-show="customer_knowledge_assessment.hold_finance_diploma === 'Y'" v-model="customer_knowledge_assessment.finance_qualification" :autosize="autosize" :placeholder="MyInputPlace" size="large" @input="customer_knowledge_assessment.finance_qualification = $util.inputReg(customer_knowledge_assessment.finance_qualification)"/>
			<!-- 问题2 -->
			<p>{{paragraph5}}</p>
			<p>{{paragraph6}}</p>
			<SwitchBoxVue :status.sync="customer_knowledge_assessment.have_other_finance_related_qualifications" :placeholder="placeholder" width="200px" class="mb20px"/>
			<el-input type="textarea" v-show="customer_knowledge_assessment.have_other_finance_related_qualifications === 'Y'" v-model="customer_knowledge_assessment.other_finance_qualification" :autosize="autosize" :placeholder="MyInputPlace" size="large" @input="customer_knowledge_assessment.other_finance_qualification = $util.inputReg(customer_knowledge_assessment.other_finance_qualification)"/>
			<!-- Investment experience -->
			<MyTitle Title="Investment experience" class="mt30px" />
			<!-- 问题3a -->
			<p>{{paragraph7}}</p>
			<SwitchBoxVue :status.sync="customer_knowledge_assessment.investment_experience_a" :placeholder="placeholder" width="200px" class="mb20px"/>
			<el-input type="textarea" v-show="customer_knowledge_assessment.investment_experience_a === 'Y'" v-model="customer_knowledge_assessment.your_investment_experience_a" :autosize="autosize" :placeholder="MyInputPlace" size="large" @input="customer_knowledge_assessment.your_investment_experience_a = $util.inputReg(customer_knowledge_assessment.your_investment_experience_a)"/>
			<!-- 问题3b -->
			<p>{{paragraph8}}</p>
			<SwitchBoxVue :status.sync="customer_knowledge_assessment.investment_experience_b" :placeholder="placeholder" width="200px" class="mb20px"/>
			<el-input type="textarea" v-show="customer_knowledge_assessment.investment_experience_b === 'Y'" v-model="customer_knowledge_assessment.your_investment_experience_b" :autosize="autosize" :placeholder="MyInputPlace" size="large" @input="customer_knowledge_assessment.your_investment_experience_b = $util.inputReg(customer_knowledge_assessment.your_investment_experience_b)"/>
			<!-- Work experience -->
			<MyTitle Title="Work experience" class="mt30px" />
			<p>{{paragraph9}}</p>
			<p>{{paragraph10}}</p>
			<SwitchBoxVue :status.sync="customer_knowledge_assessment.least_three_year_experience" :placeholder="placeholder" width="200px" class="mb20px"/>
			<el-input type="textarea" v-show="customer_knowledge_assessment.least_three_year_experience === 'Y'" v-model="customer_knowledge_assessment.your_experience" :autosize="autosize" :placeholder="MyInputPlace" size="large" @input="customer_knowledge_assessment.your_experience = $util.inputReg(customer_knowledge_assessment.your_experience)"/>
		</template>
		<!-- 已评估页面 -->
		<template v-if="$store.state.KYC.CKA !== -1">
			<div class="resultBox displayFlex flexColumn flexAlignCenter">
				<!-- <img :src="imgSrc[ $store.state.KYC.CKA !== '0' ? 'Y' : 'N']" alt=''> -->
				<p v-if="$store.state.KYC.CKA.indexOf('1') > -1">You are assessed to have the relevant experience and/or knowledge in ILP and CIS.</p>
				<p v-if="$store.state.KYC.CKA.indexOf('2') > -1">You are assessed to have the relevant experience and/or knowledge in
					unlisted Specified Investment Products (other than CIS and ILPs).</p>
				<p v-if="$store.state.KYC.CKA === '0'">You are assessed NOT to have the relevant experience and/or
					knowledge in ILP, CIS and unlisted Specified Investment Products.</p>
			</div>
		</template>
		<el-button type="warning" size="medium" @click="reTake" v-if="$store.state.KYC.CKA !== -1" style="position: fixed;bottom: 20px;">Re-Take</el-button>
	</div>
</template>

<script>
import MyTitle from "../components/MyTitle.vue";
import SwitchBoxVue from "../../../components/switchBox.vue";

export default {
	components: { MyTitle, SwitchBoxVue },
	data () {
		return {
			imgSrc: {
				Y: require('../../../assets/img/cka/CKA.passed.lg.png'),
				N: require('../../../assets/img/cka/CKA.fail.lg.png')
			},
			autosize: { minRows: 5, maxRows: 10},
			MyInputPlace: 'Please state reason',
			placeholder: 'Please provide details',
			paragraph1: "It is important to find out if you have the knowledge or investment experience to understand the risks and features of unlisted 'Specified "
				+ "Investment Products' (SIP) which include unit trusts, Investment-Linked Policies and securities. This assessment, known as "
				+ "the Customer Knowledge Assessment (CKA), helps to assess your knowledge or investment experience before a solution(s) may be offered "
				+ "to you. You understand that any inaccurate or incomplete information provided by you may affect the outcome of the assessment and the "
				+ "suitability of the recommendations made.",
			paragraph2: "You also acknowledge and agree that a copy of this form will be submitted to the relevant investment platform. By proceeding to "
				+ "provide these information, you have given consent to the collection, use and disclosure of these information.",
			paragraph3: "1. Do you have any diploma or higher qualification in the finance-related disciplines?",
			paragraph4: "Example: accountancy, actuarial science, business/business administration/business management/business studies, capital markets, "
				+ "commerce, economics, finance, financial engineering, financial planning, computational finance and insurance.",
			paragraph5: "2. Do you have any other professional finance-related qualifications?",
			paragraph6: "Example: Chartered Financial Analyst (CFA), the Association of Chartered Certified Accountants (ACCA), Associate Wealth Planner or "
				+ "Certified Financial Planner, Certified Financial Risk Manager, Chartered Alternative Investment Analyst, Chartered Financial Consultant.",
			paragraph7: "3a. Have you made at least 6 transactions in collective investment schemes ('CIS') (e.g.: unit trusts) or investment-linked policies "
				+ "('ILPs') or securities in the preceding 3 years?",
			paragraph8: "3b. Have you made at least 6 transactions in unlisted Specified Investment Products (other than CIS and ILPs) in the preceding 3 years?",
			paragraph9: "4. Do you have a minimum of 3 consecutive years of working experience in the past 10 years in the development of, structuring of, "
				+ "management of, sale of, trading of, research on and analysis of investment products or the provision of training in investment products?",
			paragraph10: "Note: work experience in accountancy, actuarial science, treasury or financial risk management activities will also be considered relevant experience.",
			customer_knowledge_assessment: {
				additional_notice: '',
				hold_finance_diploma: '', //1
				finance_qualification: '',
				have_other_finance_related_qualifications: '', //2
				other_finance_qualification: '',
				investment_experience_a: '', //3
				your_investment_experience_a: '',
				investment_experience_b: '',
				your_investment_experience_b: '',
				least_three_year_experience: '', //4
				your_experience: '',
				assessment_outcome: '',
				// 不明确字段
				have_six_transactions: '',
				your_transactions: '',
			},
			// 必填项
			keys: [
				'hold_finance_diploma',
				'have_other_finance_related_qualifications',
				'investment_experience_a',
				'investment_experience_b',
				'least_three_year_experience'
			],
			kycAllData: {}
		};
	},
	computed: {
		CKA () {
			return this.$store.state.KYC.CKA
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		submit_Form () {
			if (this.isValidate()) {
				this.$confirm('Please complete the questionnaire', 'Alert', {
					cancelButtonText: 'OK',
					showConfirmButton: false
				}).catch(() => { })
				return
			}
			const value = this.customer_knowledge_assessment
			let result = '';
			if (
				value.hold_finance_diploma === 'Y' ||
				value.have_other_finance_related_qualifications === 'Y' ||
				value.least_three_year_experience === 'Y'
			) {
				result = '1,2';
			} else if (value.investment_experience_a === 'Y') {
				result += '1';
				if (value.investment_experience_b === 'Y') {
					result += '1,2';
				}
			} else if (value.investment_experience_b === 'Y') {
				result += '2';
				if (value.investment_experience_a === 'Y') {
					result += '1,2';
				}
			} else {
				result = '0';
			}
			// 设置页面为结果页
			this.$store.commit('SET_CKA', result)
			this.customer_knowledge_assessment.assessment_outcome = result
			this.setKYC(this.kycClient)
		},
		submitForm () {
			this.$emit('nextBtnRequest', 8)
		},
		setKYC (val) {
			// 赋值
			Object.keys(this.kycAllData.kyc.client[val].customer_knowledge_assessment).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].customer_knowledge_assessment[key] = this.customer_knowledge_assessment[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 判断必填项是否填写
		isValidate () {
			return this.keys.some(key => {
				let valueValid = false
				if (this.customer_knowledge_assessment[key] === 'Y') {
					valueValid = this.checkField(key)
				}
				return this.customer_knowledge_assessment[key] === '' || valueValid;
			})
		},
		checkField (key) {
			switch (key) {
				case 'hold_finance_diploma':
					return !this.customer_knowledge_assessment['finance_qualification']
				case 'have_other_finance_related_qualifications':
					return !this.customer_knowledge_assessment['other_finance_qualification']
				case 'investment_experience_a':
					return !this.customer_knowledge_assessment['your_investment_experience_a']
				case 'investment_experience_b':
					return !this.customer_knowledge_assessment['your_investment_experience_b']
				case 'least_three_year_experience':
					return !this.customer_knowledge_assessment['your_experience']
			}
		},
		// 重新评估
		reTake () {
			this.$store.commit('SET_CKA', -1)
			// 清空值
			Object.keys(this.customer_knowledge_assessment).map(key => {
				if (key !== 'additional_notice') this.customer_knowledge_assessment[key] = ''
			})
		},
		getDataFromAll (val) {
			Object.keys(this.customer_knowledge_assessment).map(key => {
				this.customer_knowledge_assessment[key] = this.kycAllData.kyc.client[val].customer_knowledge_assessment[key]
			})
			if (!this.customer_knowledge_assessment.assessment_outcome) {
				this.$store.commit('SET_CKA', -1)
			} else {
				this.$store.commit('SET_CKA', this.customer_knowledge_assessment.assessment_outcome)
			}
			console.log(this.customer_knowledge_assessment, 'custom');
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	destroyed () { // this.setKYC(this.kycClient)
	},
}
</script>
<style lang='css' scoped>
p {
	margin-top: 30px;
	font-size: 20px;
	color: #707070;
	line-height: 28px;
	letter-spacing: 0px;
}
.switchBox {
	margin-top: 20px;
}
.el-textarea .el-textarea__inner {
	font-size: large;
}
.resultBox p {
	margin-bottom: 30px;
	font-size: 28px;
	color: #141414;
}
</style>