<template>
	<div class='wrapper'>
		<!-- 表格 Dependant's Details -->
		<el-table :data="assetsTableData" :cell-style="cellStyle" style="width: 100%">
			<el-table-column prop="name" label="Type of funds">
			</el-table-column>
			<el-table-column prop="Annual" :label="'Annual Amount \n (Regular Premium)'" width="300">
				<template slot-scope="scope">
					<span v-if="scope.row.name === 'Total'">{{scope.row.AnnualShow}}</span>
					<el-input v-else v-model="scope.row.AnnualShow" @keyup.native="proving($event)" @blur="inputBlur(scope.row, 'annual')" @focus="inputFocus(scope.row, 'annual')" @input="val => handleInput(val, scope.row.index, 'annual')" />
				</template>
			</el-table-column>
			<el-table-column prop="Single" :label="'Lump Sum \n (Single Premium)'" width="300">
				<template slot-scope="scope">
					<span v-if="scope.row.name === 'Total'">{{scope.row.SingleShow}}</span>
					<el-input v-else v-model="scope.row.SingleShow" @keyup.native="proving($event)" @blur="inputBlur(scope.row, 'single')" @focus="inputFocus(scope.row, 'single')" @input="val => handleInput(val, scope.row.index, 'single')" />
				</template>
			</el-table-column>
		</el-table>
		<!-- issue -->
		<p class="mt20px">Is the budget you set aside more than 50% of your assets or surplus?</p>
		<SwitchBox :status.sync="budget.is_reserve_half_assets" width="200px" />
		<!-- 选择yes时展示 -->
		<div v-show="budget.is_reserve_half_assets === 'Y'" class="warningBox mt20px ">
			<p class="pl20px">You may encounter a potential risk in the future of being unable to
				continue paying your premiums.</p>
			<el-input  v-model="budget.deviation_reason" type="textarea" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" size="large" />
		</div>
	</div>
</template>

<script>
import SwitchBox from "../../../components/switchBox.vue";

export default {
	components: { SwitchBox },
	data () {
		return {
			assetsTableData: [
				{
					name: 'Cash',
					Annual: '0',
					Annual_value: 'cash_annual',
					Single: '0',
					Single_value: 'cash_single'
				}, {
					name: 'CPF Ordinary Account',
					Annual: '0',
					Annual_value: 'cpf_ordinary_account_annual',
					Single: '0',
					Single_value: 'cpf_ordinary_account_single'
				}, {
					name: 'CPF Special Account',
					Annual: '0',
					Annual_value: 'cpf_special_account_annual',
					Single: '0',
					Single_value: 'cpf_special_account_single'
				}, {
					name: 'CPF Medisave Account',
					Annual: '0',
					Annual_value: 'cpf_medisave_account_annual',
					Single: '0',
					Single_value: 'cpf_medisave_account_single'
				}, {
					name: 'Supplementary Retirement Scheme (SRS)',
					Annual: '0',
					Annual_value: 'retirement_account_annual',
					Single: '0',
					Single_value: 'retirement_account_single'
				}, {
					name: 'Total',
					Annual: '0',
					Annual_value: 'total_annual',
					Single: '0',
					Single_value: 'total_single'
				},
			],
			budget: {
				additional_notice: '',
				is_reserve_half_assets: '', // 预算是否预留了超过50%的资产或盈余？
				deviation_reason:'',
			},
			kycAllData: {},
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		cellStyle ({row, column, rowIndex, columnIndex}) {
			if (columnIndex == 0 && row.name === 'Total') {
				return 'text-align: right;padding-right: 30px;'
			}
		},
		submitForm () {
			this.setKYC(this.kycClient)
			if (parseFloat(this.kycAllData.kyc.client[this.kycClient].budget.total_annual) == 0 &&
			parseFloat(this.kycAllData.kyc.client[this.kycClient].budget.total_single) == 0) {
				this.$confirm('Are you sure you have finished the data entry of this page?', 'Alert', {
					confirmButtonText: 'Confirm',
					cancelButtonText: 'Cancel',
				}).then(() => {
					this.$emit('nextBtnRequest')
				}).catch(() => { })
			} else {
				this.$emit('nextBtnRequest')
			}
		},
		setKYC (val) {
			this.assetsTableData.map(res => {
				this.kycAllData.kyc.client[val].budget[res.Annual_value] = res.Annual
				this.kycAllData.kyc.client[val].budget[res.Single_value] = res.Single
			})
			this.kycAllData.kyc.client[val].budget.is_reserve_half_assets = this.budget.is_reserve_half_assets
			this.kycAllData.kyc.client[val].budget.deviation_reason = this.budget.deviation_reason
			
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		//限制只能输入一个小数点
		proving (e) {
			var val = e.target.value
			if (val.indexOf(".") != -1) {
				var str = val.substr(val.indexOf(".") + 1)
				if (str.indexOf(".") != -1) {
					val = val.substr(0, val.indexOf(".") + str.indexOf(".") + 1)
				}
			}
			e.target.value = val.replace(/[^\d^\.]+/g, '')
		},
		// 失焦 格式化数值，四舍五入，保留两位小数
		inputBlur (e, label) {
			let obj = this.assetsTableData[e.index]
			if (label === 'annual') {
				obj.AnnualShow = 'S$ ' + (Math.round(e.Annual * 100) / 100).toFixed(2)
			} else {
				obj.SingleShow = 'S$ ' + (Math.round(e.Single * 100) / 100).toFixed(2)
			}
			this.assetsTableData.splice(e.index, 1, obj)
			// 计算total
			let totalAnnual = 0
			let totalSingle = 0
			this.assetsTableData.map(res => {
				if (res.name !== 'Total') {
					totalAnnual += parseFloat(res.Annual || 0)
					totalSingle += parseFloat(res.Single || 0)
				}
			})
			let lastObj = this.assetsTableData[this.assetsTableData.length - 1]
			lastObj.Annual = parseFloat(totalAnnual).toString()
			lastObj.AnnualShow = 'S$ ' + parseFloat(totalAnnual).toFixed(2)
			lastObj.Single = parseFloat(totalSingle).toString()
			lastObj.SingleShow = 'S$ ' + parseFloat(totalSingle).toFixed(2)
			this.assetsTableData.splice(this.assetsTableData.length - 1, 1, lastObj)
		},
		// 获取焦点 展示赋值
		inputFocus (e, label) {
			let obj = this.assetsTableData[e.index]
			if (label === 'annual') {
				if (e.Annual == 0) e.Annual = ''
				obj.AnnualShow = e.Annual
			} else {
				if (e.Single == 0) e.Single = ''
				obj.SingleShow = e.Single
			}
			this.assetsTableData.splice(e.index, 1, obj)
		},
		// 实际赋值
		handleInput (e, i, label) {
			let obj = this.assetsTableData[i]
			let data =
				('' + e) // 第一步：转成字符串
					.replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
					.replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
					.replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
					.match(/^\d*(\.?\d{0,10})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
			if (label === 'annual') {
				obj.Annual = data
			} else {
				obj.Single = data
			}
			this.assetsTableData.splice(i, 1, obj)
		},
		getDataFromAll (val) {
			this.assetsTableData.map((key, i) => {
				key.Annual = this.kycAllData.kyc.client[val].budget[key.Annual_value]
				key.AnnualShow = 'S$ ' + this.$formatMoney((Math.round(key.Annual * 100) / 100))
				key.Single = this.kycAllData.kyc.client[val].budget[key.Single_value]
				key.SingleShow = 'S$ ' + this.$formatMoney((Math.round(key.Single * 100) / 100))
				key.index = i
			})
			this.assetsTableData = [...this.assetsTableData]
			this.budget.is_reserve_half_assets = this.kycAllData.kyc.client[val].budget.is_reserve_half_assets
			this.budget.deviation_reason = this.kycAllData.kyc.client[val].budget.deviation_reason
			
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	destroyed () { // this.setKYC(this.kycClient)
	}
}
</script>
<style lang='scss' scoped>
p {
	color: #6c6c6c;
	font-size: 22px;
	margin-bottom: 20px;
}
.warningBox {
	border: 1px solid #ff9941;
	border-radius: 5px;
	height: 50px;
}
.warningBox p {
	color: #ff9941;
	line-height: 50px;
}
.el-table /deep/ th.el-table__cell > .cell {
 white-space:pre-line;
}
</style>