<template>
	<div class='wrapper'>
		<p>Do you intend to purchase a policy to replace in part or full any existing or recently terminated insurance policy or investment
			product from any insurer or other financial institution?</p>
		<SwitchBox :status.sync="switching_replacement_policy.is_purchase" width="200px" class="mb20px" />
		<!-- 选择yes时展示 -->
		<template v-if="switching_replacement_policy.is_purchase === 'Y'">
			<!-- 产品 -->
			<div v-for="(item, i) in switching_replacement_policy.products" :key="i" style="padding: 10px 0">
				<CheckBox :singleChecked.sync="item.checked">
					<span style="font-size: 16px;color: black;font-weight: 400;">{{item.product_name}}</span>
				</CheckBox>
				<el-input v-if="item.checked === 'Y'" v-model="item.reason" type="textarea" placeholder="Reason" class="mt10px" @input="item.reason = $util.inputReg(item.reason)"></el-input>
			</div>
			<p class="title">Client's declaration on policy replacement</p>
			<span>Is the replacement of policy advised by the representative?</span>
			<SwitchBox :status.sync="switching_replacement_policy.is_advise_replace_policy" width="200px" class="mb20px mt20px" />
			<span>My/Our representative has explained the following to my/our
				satisfaction in the event a replacement of policy should take place.</span>
			<span>a) I/We may incur transaction costs without gaining any real benefit from the replacement.</span>
			<span>b) I/We may incur penalties for terminating any of my/our existing policies.</span>
			<span>c) I/We may not be insurable at standard terms.</span>
			<span>d) The replacement plan may offer a lower level of benefit at a higher cost or same cost, or offer the same level of benefit at a higher cost.</span>
			<span>e) The replacement plan may be less suitable and the terms and conditions may differ.</span>
			<span>f) There may be other options available besides policy replacement (e.g. free switching facilities for investment policy).</span>
			<span>g) Upon new Insurer's acceptance of your shield plans applications, any Medishield-approved Integrated Shield Plan with another Private Medical Insurance Scheme (PMIS) will be automatically terminated.</span>
			<SwitchBox :status.sync="switching_replacement_policy.is_explained" width="200px" class="mb20px mt20px" />
			<!-- Representative’s------------------------------------------------------ -->
			<p class="title">Representative’s declaration on policy replacement</p>
			<span>
				I have explained to the client the possible disadvantages of policy
				replacement and where applicable, informed him/her of other options
				available besides policy replacement.
			</span>
			<span>
				I have also explained the basis for policy replacement and why the
				replacement of policy is suitable for the client below:
			</span>
			<MyInput placeholder="Reason for replacement" :textareaValue.sync="switching_replacement_policy.replacement_declaration"></MyInput>
		</template>
	</div>
</template>

<script>
import SwitchBox from "../../../components/switchBox.vue";
import CheckBox from '../components/checkBox.vue';
import MyInput from "../components/MyInput.vue";

export default {
	components: { SwitchBox, MyInput, CheckBox },
	data () {
		return {
			switching_replacement_policy: {
				is_purchase: '',
				replacement_declaration: '',
				is_explained: '',
				is_advise_replace_policy: '',
				additional_notice: '',
				products: [],
				// 不清楚字段
				no_purchase_reason: ''
			},
			kycAllData: {}
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			this.$emit('nextBtnRequest')
		},
		setKYC (val) {
			Object.keys(this.kycAllData.kyc.client[val].switching_replacement_policy).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].switching_replacement_policy[key] = this.switching_replacement_policy[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		getDataFromAll (val) {
			// 若有值 则赋值
			Object.keys(this.switching_replacement_policy).map(key => {
				this.switching_replacement_policy[key] = this.kycAllData.kyc.client[val].switching_replacement_policy[key]
			})
			// 展示所有product，产品可能增删改，故需初始化
			let productList = []
			this.kycAllData.kyc.client[val].basis_recommendations.product_recommendations_option.map(proposal => {
				proposal.product_list.map(pro => {
					let obj = {
						checked: 'N',
						product_id: pro.product_id,
						product_name: pro.product_name,
						reason: ''
					}
					productList.push(obj)
					pro.fund_list.map(fund => {
						let fundObj = {
							checked: 'N',
							product_id: fund.fund_id,
							product_name: fund.fund_name,
							reason: ''
						}
						productList.push(fundObj)
					})
				})
			})
			this.switching_replacement_policy.products.map(oldPro => {
				productList.map(newPro => {
					if (oldPro.product_id === newPro.product_id && oldPro.product_name === newPro.product_name) {
						newPro.checked = oldPro.checked
						newPro.reason = oldPro.reason
					}
				})
			})
			this.switching_replacement_policy.products = productList
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	destroyed () {// this.setKYC(this.kycClient)
	},
}
</script>
<style lang='css' scoped>
p {
	font-size: 20px;
	color: #707070;
	line-height: 28px;
	letter-spacing: 0px;
	margin-bottom: 20px;
}
.title {
	font-size: 20px;
	color: #6c6c6c;
	font-weight: 600;
	margin-bottom: 20px;
}
span {
	line-height: 24px;
	display: block;
	font-size: 16px;
}
</style>