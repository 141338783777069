<template>
	<div class='wrapper'>
		<MyTitle Title="General Disclaimer" class="mt20px" />
		<p>{{paragraph1}}</p>
		<img :src="isAI ? require('@/assets/img/ai/AI.png') : require('@/assets/img/ai/AI.no.png')" alt=''>
		<p>{{paragraph2}}</p>
		<SwitchBox :status.sync="accredited_investor_declaration.is_accredited_investor" width="200px" />
		<template v-if="accredited_investor_declaration.is_accredited_investor === 'Y'">
			<p>{{paragraph3}}</p>
			<p>{{paragraph4}}</p>
			<SwitchBox :status.sync="accredited_investor_declaration.net_personal_assets" width="200px" />
			<p>{{paragraph5}}</p>
			<SwitchBox :status.sync="accredited_investor_declaration.financial_assets" width="200px" />
			<p>{{paragraph6}}</p>
			<p>{{paragraph7}}</p>
			<p>{{paragraph8}}</p>
			<p>
				{{paragraph9}}
				<el-input v-model="accredited_investor_declaration.income_others" style="width: 85%;" @input="accredited_investor_declaration.income_others = $util.inputReg(accredited_investor_declaration.income_others)"></el-input>
			</p>
			<SwitchBox :status.sync="accredited_investor_declaration.income" width="200px" />
			<!-- Accredited Investor Declaration -->
			<MyTitle Title="Accredited Investor Declaration" class="mt20px" />
			<p>{{Declaration1}}</p>
			<p>{{Declaration2}}</p>
			<p>{{Declaration3}}</p>
			<p>{{Declaration4}}</p>
		</template>
	</div>
</template>

<script>
import SwitchBox from "../../../components/switchBox.vue";
import MyTitle from "../components/MyTitle.vue";

export default {
	components: { SwitchBox, MyTitle },
	data () {
		return {
			// 公认的投资者被认为更知情，更能够获得资源以保护自己的利益，因此需要更少的监管保护。因此，同意被视为合格投资者的投资者放弃了某些监管保障措施的好处。例如，证券发行人可免于就仅向合格投资者发出的要约发行在新加坡金融管理局注册的完整招股说明书，中介机构在与合格投资者交易时可免于遵守许多商业行为要求。如果投资者不理解被视为合格投资者的任何后果，应咨询专业顾问。
			paragraph1: "Accredited investors are assumed to be better informed, and better able to access resources to protect their own interests, and "
				+ "therefore require less regulatory protection. Investors who agree to be treated as accredited investors therefore forgo the benefit of "
				+ "certain regulatory safeguards. For example, issuers of securities are exempted from issuing a full prospectus registered with the "
				+ "Monetary Authority of Singapore in respect of offers that are made only to accredited investors, and intermediaries are exempted from a "
				+ "number of business conduct requirements when dealing with accredited investors. Investors should consult a professional adviser if they "
				+ "do not understand any consequence of being treated as an accredited investor.",
			paragraph2: "Do you want to be assessed and treated like an Accredited Investor if you qualify?",
			paragraph3: "(i) For individual (choose the option below):",
			paragraph4: "a) my net personal assets exceed in value S$2 million (or its equivalent in a foreign currency), of which my net equity in primary "
				+ "residence is capped at S$1 million; or Please complete Section “Assets & Liabilities” and attach supporting documents for each asset/liability.",
			paragraph5: " b) my financial assets (net of any related liabilities) exceed in value S$1 million (or its equivalent in a foreign currency); or "
				+ "Please complete Section “Assets & Liabilities” and attach supporting documents for each asset/ liability.",
			paragraph6: "c) my income in the preceding 12 months is not less than S$300,000 (or its equivalent in a foreign currency) Supporting documents required "
				+ "(at least one, dated within the last 3 months where applicable)",
			paragraph7: "(i)    Latest IR8A Form",
			paragraph8: "(ii)   Payslip for the last 12 months",
			paragraph9: "(iii)  Others",
			Declaration1: "(1) I / We hereby declare that I am an Accredited Investor as defined in Section 4A(1)(a)(i), (ii), (iii) or (iv) of the Securities and "
				+ "Futures Act (Cap. 289).",
			Declaration2: " (2) I / We confirm that I / we have read and understand that by providing my / our consent to be treated as an Accredited Investor (as "
				+ "defined under the Singapore laws and regulations), I / We shall take responsibility of my / our decision to purchase investment products "
				+ "and acknowledge that Infinitum will be exempted from fulfilling the below obligations stipulated in the Financial Advisers Act (“FAA”): - "
				+ "Section 25 of the FAA – Obligation to disclose product information to clients - Section 27 of the FAA – Recommendations by licensed "
				+ "financial advisers - Section 36 of the FAA – Licensed financial adviser to disclose certain interests in specified products; and I "
				+ "shall be responsible for ensuring the suitability of the financial product(s) purchased and will be unable to rely on the sections above "
				+ "to file a civil claim against Infinitum or its product and service providers in the event of a loss.",
			Declaration3: "(3) I / We hereby in the event there is any change in circumstances relating to (i) my / our net asset holdings and / or income which may  "
				+ "affect my / our declarations and representations set out above, and / or (ii) my / our “Accredited Investor” status under Singapore laws and "
				+ "regulations, I / We undertake to inform Infinitum within 7 working days of such change in circumstances.",
			Declaration4: "(4) I / We are aware that I / we may at any time withdraw my / our con (6681 5785) or verified email – BusinessSupport@Infinitumfa.com. "
				+ "Infinitum will require 5 working days upon receipt of the request to proces the relevant consent provisions.",
			accredited_investor_declaration: {
				additional_notice: '',
				is_ai: 'N',
				is_accredited_investor: '',
				net_personal_assets: '',
				financial_assets: '',
				income: '',
				income_others: '',
			},
			kycAllData: {}
		};
	},
	computed: {
		isAI () {
			return this.accredited_investor_declaration.is_accredited_investor === 'Y' && (
				this.accredited_investor_declaration.net_personal_assets === 'Y' ||
				this.accredited_investor_declaration.financial_assets === 'Y' ||
				this.accredited_investor_declaration.income === 'Y')

		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			this.$emit('nextBtnRequest', 10)
		},
		setKYC (val) {
			this.accredited_investor_declaration.is_ai = this.isAI ? 'Y' : 'N'
			// 赋值
			Object.keys(this.kycAllData.kyc.client[val].accredited_investor_declaration).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].accredited_investor_declaration[key] = this.accredited_investor_declaration[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		getDataFromAll (val) {
			Object.keys(this.accredited_investor_declaration).map(key => {
				this.accredited_investor_declaration[key] = this.kycAllData.kyc.client[val].accredited_investor_declaration[key]
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	destroyed () { // this.setKYC(this.kycClient)
	},
}
</script>
<style lang='css' scoped>
p {
	margin-top: 30px;
	font-size: 20px;
	color: #707070;
	line-height: 28px;
	letter-spacing: 0px;
}
.switchBox {
	margin-top: 20px;
}
</style>